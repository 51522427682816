import { fetchServer } from "@features/utils/fetch-server";
import { CommandType, ReturnType } from "../types";

export class SupplierApiClient {
  static getMotifRefus = async () => {
    const data = await fetchServer("/supplier/return/reason");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: { code: any; lib: any }) => {
      return { label: el.lib, value: el.code };
    });
  };

  static getModesExpedition = async () => {
    const data = await fetchServer("/supplier/shippingmode");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: { code: any; lib: any }) => {
      return { label: el.lib, value: el.code };
    });
  };

  static createRetour = async (retourInfos: ReturnType, codeLieu: string) => {
    const data = await fetchServer("/supplier/return", {
      method: "PUT",
      body: JSON.stringify({
        codeLieuParQui: codeLieu,
        codeLieuPourQui: codeLieu,
        codeLieuExpe: codeLieu,
        codeLieuTrait: codeLieu,
        eaN13: retourInfos.id,
        qte: retourInfos.qteRetModif,
        codeMotifRet: retourInfos.motif,
        codeFourn: retourInfos.codeFourn,
        panie: retourInfos.basket,
        demandeAut: retourInfos.demandeAut,
      }),
    });
    if (data.status !== 204) {
      throw new Error("Erreur lors de la création de retour");
    }
    return true;
  };

  static createOrder = async (orderInfos: CommandType, codeLieu: string) => {
    const data = await fetchServer("/supplier/order", {
      method: "PUT",
      body: JSON.stringify({
        codeLieu: codeLieu,
        eaN13: orderInfos.id,
        qte: orderInfos.qteOrdered,
        codeModeExpe: orderInfos.codeModeExpe,
        codeFourn: orderInfos.codeFourn,
        panie: orderInfos.basket,
        tauxNet: 0,
        tauxNetNet: 0,
        tauxAs: 0,
      }),
    });
    if (data.status !== 204) {
      throw new Error("Erreur lors de la création de commande");
    }
    return true;
  };
}
