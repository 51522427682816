import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputCounter } from "@atoms/input/input-counter";
import { Input } from "@atoms/input/input-text";
import { Base, BaseBoldSmall, BaseSmall, Info, InfoSmall } from "@atoms/text";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { ProductsApiClient } from "@features/products/api-client/api-client";
import { Book } from "@features/products/types";
import { ReceptionApiClient } from "@features/reception/api-client/api-client";
import { SelectedItemReception } from "@features/reception/state/store";
import { useReception } from "@features/reception/state/use-reception";
import {
  ReceptionLine,
  ReceptionSearchResult,
  ReceptionUnit,
} from "@features/reception/types";
import { ROUTES } from "@features/routes";
import { getCommonProductTooltip } from "@features/utils/format/strings";
import {
  ArrowSmRightIcon,
  CurrencyEuroIcon,
  InformationCircleIcon,
  TableIcon,
  TagIcon,
} from "@heroicons/react/outline";
import { GenericCard } from "@molecules/cards";
import { AffectationCmdClientModalAtom } from "@views/client/reception/receptions-modals/affection-commande-client-modal";
import { PrintLabelsModal } from "@views/client/reception/receptions-modals/print-labels-modal";
import { ReceptionInfoModalAtom } from "@views/client/reception/receptions-modals/reception-info-modal";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaBoxOpen, FaBox, FaWarehouse } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

export const getTypeLibele = (item: ReceptionSearchResult) => {
  if (item.numBL !== "") return { label: "Bon de livraison", value: "BL" };
  if (item.numColis !== "") return { label: "Colis", value: "COLIS" };
  if (item.numCde !== "") return { label: "Commande", value: "CDE" };
  return { label: "Fournisseur", value: "FOURN" };
};

export const getLibel = (item: ReceptionUnit) => {
  if (item.listReceiptSupplierOrder) {
    return item.listReceiptSupplierOrder.length > 1
      ? `${item.typeRecepLibel} (${item.listReceiptSupplierOrder[0].numCde} ...)`
      : `${item.typeRecepLibel} (${item.listReceiptSupplierOrder[0].numCde})`;
  } else {
    return item.typeRecepLibel;
  }
};

export const ReceptionCard = (props: { item: ReceptionUnit }) => {
  const setReceptionInfoModal = useSetRecoilState(ReceptionInfoModalAtom);
  const { changeSelectedReception } = useReception();

  return (
    <div className="relative">
      <div className="group flex flex-col h-26 rounded-lg relative ">
        <div className="flex flex-row gap-1 h-8 justify-start rounded-lg mx-2 mt-2 group-hover:mx-4 ">
          <div className="flex flex-col justify-center items-end w-6 h-6">
            <BookRender src="" productType={props.item.modeGest} />
          </div>
          <BaseBoldSmall className="group-hover:underline whitespace-nowrap overflow-hidden text-ellipsis cursor-text">
            {props.item.codeFourn} - {props.item.numBL}
          </BaseBoldSmall>
        </div>
        <div className="flex flex-col h-full w-full mx-4 mb-2 rounded-lg">
          <Info>{props.item.indicFactFourn}</Info>
          <Info>Réception: {props.item.numRecep}</Info>
          <Info>{getLibel(props.item)}</Info>
          <Info>{props.item.nbrColis} colis</Info>
        </div>
        <div
          className="absolute bg-primary p-1 rounded-tl-lg rounded-br-lg bottom-0 right-0 transition duration-200 flex items-center justify-center text-blue-600 bg-blue-100"
          data-tooltip="Accéder à la réception en cours"
        >
          <ArrowSmRightIcon className="w-5 h-5 group-hover:w-6 group-hover:h-6" />
        </div>
      </div>
      <div
        id="info"
        className="absolute bg-primary p-1 rounded-tr-lg rounded-bl-lg top-0 right-0 transition duration-200 flex items-center justify-center text-blue-600 bg-blue-100"
        onClick={(e) => {
          e.stopPropagation();
          changeSelectedReception(props.item);
          setReceptionInfoModal(true);
        }}
      >
        <InformationCircleIcon
          className="w-5 h-5 hover:w-6 hover:h-6"
          data-tooltip="Informations sur la réception"
        />
      </div>
    </div>
  );
};

export const CommandCard = (props: {
  item: { title: string; autre: string };
}) => {
  return (
    <GenericCard
      title={"Commande"}
      checkable={true}
      item={props.item}
      onInfoClick={(item) => console.log(item)}
    >
      <Base>{props.item.title}</Base>
      <Base>xxx/xxx/xxx</Base>
      <Base>xxx références</Base>
      <Base>xxx articles</Base>
    </GenericCard>
  );
};

export const BLCard = (props: { item: { title: string; autre: string } }) => {
  return (
    <GenericCard
      title={"Colis"}
      item={props.item}
      onInfoClick={(item) => console.log(item)}
    >
      <Base>XXXXXXXXX</Base>
      <Base>xxx références</Base>
      <Base>xxx articles</Base>
    </GenericCard>
  );
};

export const ReceptionSearchCard = (props: { line: ReceptionSearchResult }) => {
  const navigate = useNavigate();
  const libele = getTypeLibele(props.line);
  return (
    <div
      className="flex flex-col grow h-20"
      onClick={() => {
        navigate(ROUTES.OngoingReception);
        //navigate(ROUTES.SupplierPage);
      }}
    >
      <div className="flex flex-row h-8 justify-start bg-slate-300">
        <div className="flex flex-col justify-center">
          {libele.value === "FOURN" ? (
            <FaWarehouse className="w-full h-full text-lg p-1" />
          ) : props.line.recepOuver ? (
            <FaBoxOpen className="w-full h-full text-lg p-1" />
          ) : (
            <FaBox className="w-full h-full text-lg p-1" />
          )}
        </div>
        <div className="flex flex-col justify-center">
          <Base> - {libele.label}</Base>
        </div>
      </div>
      <div className="grow flex flex-col justify-center items-center">
        <Base>
          {props.line.numBL + props.line.numColis + props.line.numCde}{" "}
          {props.line.fourn !== "" ? `(${props.line.fourn})` : ""}
        </Base>
      </div>
    </div>
  );
};

export const SupplierPackageCard = (props: { line: any }) => {
  const libele = getTypeLibele(props.line);
  return (
    <div className="flex flex-col grow h-20" onClick={() => {}}>
      <div className="flex flex-row h-8 justify-start bg-blue-100">
        <div className="flex flex-col justify-center">
          {/* {props.line.numBL === "" &&
          props.line.numColis === "" &&
          props.line.numCde === "" ? (
            <FaWarehouse className="w-full h-full text-lg p-1" />
          ) : props.line.recepOuver ? (
            <FaBoxOpen className="w-full h-full text-lg p-1" />
          ) : (
            <FaBox className="w-full h-full text-lg p-1" />
          )} */}
        </div>
        <div className="flex flex-col justify-center">
          <Base> - {libele.label}</Base>
        </div>
        <div className="flex flex-col grow justify-center items-end">
          <Checkbox className="border-slate-500 mx-2" />
        </div>
      </div>
      <div className="flex grow">
        <div className="grow flex flex-col justify-center items-center">
          <Base>
            {props.line.numBL + props.line.numColis + props.line.numCde}{" "}
            {props.line.fourn !== "" ? `(${props.line.fourn})` : ""}
          </Base>
        </div>
        <div className="flex flex-col justify-center items-center mx-1">
          <Button
            theme="secondary"
            icon={({ className }) => (
              <InformationCircleIcon className={className} />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export const ReceptionProductActions = ({
  reception,
  product,
  shortcuts,
  size,
  autoFocusQuantity,
  lock,
  disabled,
  disableOnSelected,
}: {
  reception: ReceptionUnit;
  product: ReceptionLine;
  shortcuts?: boolean;
  size?: "lg" | "md" | "sm";
  autoFocusQuantity?: boolean;
  lock?: boolean;
  disabled?: boolean;
  disableOnSelected?: boolean;
}) => {
  size = size || "sm";

  const { receiptLines, setReceiptLines, getRelatedOrders, handleCommit } =
    useReception();
  const [selectedItem, setSelectedItem] = useRecoilState(SelectedItemReception);

  const setAffectationCmdClient = useSetRecoilState(
    AffectationCmdClientModalAtom
  );

  const setPrintLabelsModal = useSetRecoilState(PrintLabelsModal);

  return (
    <>
      {product.linePk !== "" && product.qteCdeCli > 0 && (
        <Button
          className="shrink-0"
          size={size}
          onClick={async (e) => {
            e.stopPropagation();
            const orders = await getRelatedOrders(
              reception,
              product.eaN13,
              product.qteRecu
            );
            setAffectationCmdClient({
              open: true,
              receipt: reception,
              item: product,
              orders: orders,
              total: selectedItem?.qteRecu,
            });
          }}
          theme="secondary"
          data-tooltip="Affectations commandes clients"
          icon={({ className }) => <TableIcon className={className} />}
        />
      )}

      {product.linePk !== "" && product.linePk !== selectedItem?.linePk && (
        <Button
          className="shrink-0"
          disabled={product.qteCdeCli > 0}
          size={size}
          onClick={(e) => {
            e.stopPropagation();
            setPrintLabelsModal({ open: true, item: product });
          }}
          theme="secondary"
          data-tooltip="Imprimer étiquettes"
          icon={({ className }) => <TagIcon className={className} />}
        />
      )}

      <div
        id={
          autoFocusQuantity && selectedItem?.eaN13 === product.eaN13
            ? "product-counter"
            : ""
        }
        style={{ maxWidth: 128, minWidth: 96 }}
      >
        <InputCounter
          size={size}
          confirmOnZero
          deleteIconOnZero
          disabled={disabled}
          // disabled={
          //   disableOnSelected && selectedItem?.linePk === product.linePk
          // }
          value={product.qteRecu}
          onChange={async (value) => {
            if (value === product.qteRecu) return;
            if (product.linePk !== selectedItem?.linePk) {
              await handleCommit({ ...product, qteRecu: value }, false);

              const indexP = receiptLines.items.findIndex(
                (p) => p.linePk === product.linePk
              );
              const tempTab = [...receiptLines.items];
              tempTab[indexP] = { ...product, qteRecu: value };
              setReceiptLines({ ...receiptLines, items: tempTab });
            } else if (selectedItem && selectedItem.linePk === product.linePk) {
              setSelectedItem({
                ...product,
                qteRecu: value,
                nbrEtiq: selectedItem && selectedItem.nbrEtiq > 0 ? value : 0,
              });
            }
          }}
        />
      </div>
    </>
  );
};

export default function ReceptionProductCard({
  reception,
  product,
  showBorderOnSelected,
  autoFocusQuantity,
  lock,
  disabled,
  disableOnselected,
}: {
  reception: ReceptionUnit;
  product: ReceptionLine;
  showBorderOnSelected?: boolean;
  disableOnselected?: boolean;
  disabled?: boolean;
  autoFocusQuantity?: boolean;
  lock?: boolean;
}) {
  const [tempItem, setTempItem] = useState(product);
  const [autoBlur, setAutoBlur] = useState(false);
  const [selectedItem, setSelectedItem] = useRecoilState(SelectedItemReception);
  const { current } = useShopLocations();

  const {
    receiptLines,
    getRelatedOrders,
    selectedReception,
    updateLine,
    setReceiptLines,
    valoLine,
  } = useReception();

  const blurCurrentInput = () => {
    const input = document.activeElement as HTMLInputElement;
    if (
      input.id === "prixBase" ||
      input.id === "tauxNet" ||
      input.id === "prixNetHt"
    )
      input.blur();
  };

  useEffect(() => {
    setTempItem({ ...product });
  }, [product]);

  //À factoriser
  const handleBlur = async (key: string, value: string) => {
    setAutoBlur(false);
    if (selectedItem?.linePk !== product.linePk) {
      const index = receiptLines.items.findIndex(
        (el) => el.linePk === product.linePk
      );

      if (index !== -1) {
        const orders = await getRelatedOrders(
          selectedReception,
          receiptLines.items[index].eaN13,
          receiptLines.items[index].qteRecu
        );
        const res = await updateLine(
          {
            ...product,
            [key]: +value,
          },
          orders
        );

        const resValo =
          (key === "prixBase" || "prixNetHt") && value === ""
            ? { tauxNet: product.tauxNet, netHT: 0 }
            : await ReceptionApiClient.valoLine(
                selectedReception,
                {
                  ...product,
                  [key]: value,
                },
                key
              );
        const indexP = receiptLines.items.findIndex(
          (p) => p.linePk === product.linePk
        );
        const tempTab = [...receiptLines.items];
        const saveTab = [...receiptLines.items];
        tempTab[indexP] = {
          ...product,
          [key]: +value,
          tauxNet: resValo.tauxNet,
          prixNetHt: resValo.netHT,
        };
        if (res) {
          setReceiptLines({ ...receiptLines, items: tempTab });
          // const lines = await getLines(selectedReception, {
          //   orderBy: "eaN13",
          //   orderDir: "DESC",
          //   pageNumber: 1,
          //   pageSize: 15,
          // });
          // setReceiptLines({
          //   pagination: {
          //     pageNumber: lines.pageNumber,
          //     pageSize: lines.pageSize,
          //     pageCount: lines.pageCount,
          //     totalRows: lines.totalRows,
          //     rowsReturned:
          //       lines.rowsReturned + receiptLines.pagination.rowsReturned,
          //   },
          //   items: lines.items,
          // });
          //setTempItem(lines.items[0]);
          setTempItem({
            ...product,
            [key]: +value,
          });
        } else setReceiptLines({ ...receiptLines, items: saveTab });
      }
      //setTabKey((previous) => previous + 1);
    } else {
      setSelectedItem({ ...selectedItem, [key]: value === "" ? 0 : +value });
    }
  };

  const handleChange = async (key: string, value: string) => {
    // if (product.linePk === selectedItem?.linePk) {
    //   valoLine(product, key, value);
    // }
    valoLine(product, key, value);

    //setTempItem({ ...selectedItem, [key]: +value } as ReceptionLine);
  };

  return (
    <div
      className={`flex grow h-52 relative ${
        showBorderOnSelected && product.linePk === selectedItem?.linePk && !lock
          ? "border-2 border-blue-600"
          : ""
      }`}
      onClick={() => {}}
    >
      <div className="shrink-0 h-full relative w-16 2xl:w-24 py-2 flex justify-center border-r border-slate-200 dark:border-slate-800 bg-cover bg-center">
        <div className="px-1 flex items-center justify-center">
          <BookRender src={product.imageURL} productType={reception.modeGest} />
        </div>
        {/* <div className="absolute bottom-2 left-2 w-9 h-9 bg-white dark:bg-slate-900 flex items-center justify-center">
          {getProductTypeIcon(product.codeTypeProd)}
        </div> */}
      </div>
      <div className="grow flex flex-col p-2 w-full overflow-hidden ">
        <BaseSmall
          className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis cursor-text"
          data-html={true}
          data-tooltip={getCommonProductTooltip(product, reception.modeGest)}
        >
          {product.desig}
        </BaseSmall>
        <BaseSmall className="block my-1 cursor-text">
          {product.eaN13}
        </BaseSmall>
        <div className="flex flex-col space-y-1 my-2 max-w-32">
          <div className="flex gap-1 items-center">
            <Input
              id="prixBase"
              value={tempItem.prixBase}
              //key={`${tempItem.tauxNet} ${tempItem.prixNetHt}`}
              // disabled={
              //   disableOnselected && selectedItem?.linePk === product.linePk
              // }
              disabled={selectedItem !== null && disableOnselected}
              size="sm"
              type="number"
              inputClassName="w-24"
              onBlur={(e) => {
                e.stopPropagation();
                handleBlur("prixBase", e.target.value);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAutoBlur(true);
              }}
              onChange={(e) => {
                e.stopPropagation();
                handleChange("prixBase", e.target.value);
              }}
            />
            <InfoSmall className="w-full">
              {current?.devisSymb || "€"}{" "}
              {reception.modeGest === "L" ? "TTC" : "HT PU"}
            </InfoSmall>
          </div>
          <div className="flex gap-1 items-center ">
            <Input
              id="tauxNet"
              value={tempItem.tauxNet}
              //key={`${product.prixBase} ${product.prixNetHt}`}

              // disabled={
              //   disableOnselected && selectedItem?.linePk === product.linePk
              // }
              disabled={selectedItem !== null && disableOnselected}
              type="number"
              size="sm"
              inputClassName="w-24"
              onBlur={(e) => {
                e.stopPropagation();
                handleBlur("tauxNet", e.target.value);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAutoBlur(true);
                // e.stopPropagation();
                // if (!selectedItem || !(selectedItem.linePk === product.linePk))
                //   if (autoBlur) {
                //     blurCurrentInput();
                //   } else {
                //     setAutoBlur(true);
                //   }
              }}
              onChange={(e) => {
                e.stopPropagation();
                handleChange("tauxNet", e.target.value);
              }}
            />
            <InfoSmall className="w-full">% remise</InfoSmall>
          </div>
          <div className="flex gap-1 items-center ">
            <Input
              id="prixNetHt"
              value={tempItem.prixNetHt}
              //key={`${product.prixBase} ${product.tauxNet}`}

              // disabled={
              //   disableOnselected && selectedItem?.linePk === product.linePk
              // }
              disabled={selectedItem !== null && disableOnselected}
              size="sm"
              type="number"
              inputClassName="w-24"
              onBlur={(e) => {
                e.stopPropagation();
                handleBlur("prixNetHt", e.target.value);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAutoBlur(true);
                // if (!selectedItem || !(selectedItem.linePk === product.linePk))
                //   if (autoBlur) {
                //     blurCurrentInput();
                //   } else {
                //     setAutoBlur(true);
                //   }
              }}
              onChange={(e) => {
                e.stopPropagation();
                handleChange("prixNetHt", e.target.value);
              }}
            />
            <InfoSmall className="w-full">
              {current?.devisSymb || "€"} HT PA
            </InfoSmall>
          </div>
          <Button
            className="hidden"
            shortcut={["enter"]}
            onClick={(e) => {
              e.stopPropagation();
              if (!selectedItem || !(selectedItem.linePk === product.linePk))
                if (autoBlur) {
                  blurCurrentInput();
                } else {
                  setAutoBlur(true);
                }
            }}
          />
        </div>
        <div className="flex space-x-2 justify-end w-full">
          <ReceptionProductActions
            product={product}
            reception={reception}
            size="sm"
            autoFocusQuantity={autoFocusQuantity}
            lock={lock}
            disableOnSelected={disableOnselected}
            disabled={selectedItem !== null && disableOnselected}
          />
        </div>
      </div>
      {selectedReception.modeGest === "L" && (
        <Button
          theme="secondary"
          className="absolute top-2 right-1 flex items-center justify-center rounded-full"
          onClick={async (e) => {
            e.stopPropagation();
            const resProduct = await ProductsApiClient.getProduct(
              product.eaN13,
              "book"
            );
            const resUpdate = await ProductsApiClient.updatePrice(
              resProduct as Book,
              product.codeLieu,
              product.prixVenteRef
            );
            if (resUpdate)
              toast.success("Prix du produit mis à jour avec succès");
          }}
          data-tooltip="MAJ prix de vente"
          icon={({ className }) => <CurrencyEuroIcon className={className} />}
        />
      )}
    </div>
  );
}
