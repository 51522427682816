import { Frame } from "@atoms/layout/frame";
import { Base, Info, InfoSmall, Title } from "@atoms/text";
import { Button } from "@atoms/button/button";
import { ROUTES } from "@features/routes";
import { Form } from "@molecules/form";
import { useLocation, useNavigate } from "react-router-dom";
import ReceptionProductCard, {
  ReceptionProductActions,
} from "@components/reception/reception-cards";
import { InputWithSuggestions } from "@atoms/input/input-with-suggestion";
import {
  ArrowNarrowLeftIcon,
  CheckCircleIcon,
  CurrencyEuroIcon,
  PaperClipIcon,
  PlusIcon,
  PrinterIcon,
  RefreshIcon,
  TagIcon,
  ViewListIcon,
} from "@heroicons/react/outline";
import Select from "@atoms/input/input-select";
import { useContext, useEffect, useState } from "react";
import { receptionInfoModalFields } from "../receptions-modals/reception-info-modal";
import { useReception } from "@features/reception/state/use-reception";
import { useTranslation } from "react-i18next";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { BookRender } from "@atoms/book";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { ReceptionLine, ReceptionUnit } from "@features/reception/types";
import { useScan } from "@atoms/input-scan/use-scan";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { toast } from "react-hot-toast";
import { useRecoilState, useSetRecoilState } from "recoil";
import { AffectationCmdClientModalAtom } from "../receptions-modals/affection-commande-client-modal";
import Accordion from "@atoms/accordion";
import { CommandFournModalAtom } from "../receptions-modals/commande-fournisseur-modal";
import {
  LockItemReception,
  SelectedItemReception,
  TabKey,
} from "@features/reception/state/store";
import { Page } from "@atoms/layout/page";
import { PdfModal } from "@molecules/pdf-viewer/pdf-viewer-modal";
import { InputFormat } from "@atoms/input/input-format";
import { ReceptionApiClient } from "@features/reception/api-client/api-client";
import { Input } from "@atoms/input/input-text";
import { focusInput } from "@features/utils/divers";
import { InputCounter } from "@atoms/input/input-counter";
import { useProducts } from "@features/products/state/use-products";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { MissingArticlesModalAtom } from "../receptions-modals/missing-articles";
import { ScanContext } from "@views/scan-provider";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { CommonPagination } from "@features/types";
import { CommonApiClient } from "@features/general/common/api-client/api-client";
import { ButtonConfirm } from "@atoms/button/confirm";
import { getCommonProductTooltip } from "@features/utils/format/strings";

export const OngoingReceptionPage = () => {
  const [clotureStep, setClotureStep] = useState(false);
  const [finishedStep, setFinishedStep] = useState(false); //Temp variable to simulate behavior 'til API available
  const {
    selectedReception,
    changeSelectedReception,
    loading,
    receiptLines,
    getLines,
    setReceiptLines,
    valoLine,
    closeReceipt,
    receiptScannedProduct,
    getReceiptDocument,
    getRelatedOrders,
    updateLine,
    getMissingArticles,
    updateAllPrices,
  } = useReception();
  const { current } = useShopLocations();
  const [selectedItem, setSelectedItem] = useRecoilState(SelectedItemReception);
  const [lock, setLock] = useRecoilState(LockItemReception);
  const [inputValue, setInputValue] = useState("");
  const [tab, setTab] = useState<any>({});
  const setCommandFournModal = useSetRecoilState(CommandFournModalAtom);
  const setPdfModal = useSetRecoilState(PdfModal);
  const { scanOn } = useScan();
  const { t } = useTranslation();
  const { getSuggestions } = useProducts();
  const navigate = useNavigate();
  const setAffectationModal = useSetRecoilState(AffectationCmdClientModalAtom);
  const setMissingArticlesModal = useSetRecoilState(MissingArticlesModalAtom);
  const [tabKey, setTabKey] = useRecoilState(TabKey);
  const context = useContext(ScanContext);
  const [tabFocusedItem, setTabFocusedItem] = useState<ReceptionLine>();
  const [tvaList, setTvaList] = useState<any>([]);

  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "reception-ongoing-grid-mode",
    {
      activated: true,
    }
  );
  const location = useLocation();
  const previousPageScannedProduct = location.state?.scannedProduct;

  useEffect(() => {
    if (selectedItem) setTab({ selectedItem: true });
    //setSelectedItemKey((previous) => previous + 1);
    //setTabKey((previous) => previous + 1);
  }, [selectedItem]);

  useControlledEffect(() => {
    document.title =
      "Inférence - " +
      `Recep. ${selectedReception.codeFourn} - ${selectedReception.numRecep}`;
  }, [selectedReception]);

  useControlledEffect(() => {
    if (previousPageScannedProduct) {
      setSelectedItem(previousPageScannedProduct);
      setTab({ selectedItem: true });
    }

    return async () => {
      setReceiptLines({
        pagination: {
          pageNumber: 0,
          pageSize: 0,
          pageCount: 0,
          totalRows: 0,
          rowsReturned: 0,
        },
        items: [],
      });
      setSelectedItem(null);
      setLock(false);
      await ReceptionApiClient.processing(selectedReception, false);
    };
  }, []);

  const handleSubmit = async (ean: string) => {
    if (ean.length >= 8) {
      const res = await receiptScannedProduct(ean);
      if (res) {
        if (res.eaN13 !== selectedItem?.eaN13) {
          setLock(true);
        }
        if (selectedItem && res.eaN13 !== selectedItem.eaN13) {
          const orders = await getRelatedOrders(
            selectedReception,
            selectedItem.eaN13,
            selectedItem.qteRecu
          );
          setInputValue("");
          if (selectedItem.qteCdeCli > 0) {
            setAffectationModal({
              open: true,
              receipt: selectedReception,
              item: selectedItem,
              orders: orders,
              queingItem: { ...res, qteRecu: 1 },
            });
            return;
          } else {
            await updateLine(selectedItem, [
              {
                codeCanalVente: "",
                numCdeCli: "",
                dateCde: null,
                nomClient: "",
                telCli: "",
                mailCli: "",
                qteCde: 0,
                qteRest: 0,
                qteProposee: selectedItem.qteRecu,
                nbrEtiq: 0,
              },
            ]);
            setSelectedItem({
              ...res,
              qteRecu:
                selectedItem && res.eaN13 === selectedItem.eaN13
                  ? selectedItem.qteRecu + 1
                  : 1,
              rayonObj: { label: res.libRayon, value: res.codeRayon },
              fabObj: { label: res.editFab, value: res.codeEditFab },
            });
            const updatedLines = await getLines(selectedReception, {
              orderBy: "eaN13",
              orderDir: "DESC",
              pageNumber: 0,
              pageSize: 15,
            });
            setReceiptLines({
              pagination: {
                pageNumber: updatedLines.pageNumber,
                pageSize: updatedLines.pageSize,
                pageCount: updatedLines.pageCount,
                totalRows: updatedLines.totalRows,
                rowsReturned: updatedLines.rowsReturned,
              },
              items: updatedLines.items,
            });
            return;
          }
        }
        setSelectedItem((previous) => {
          return {
            ...res,
            qteRecu: context.saveFocused
              ? +context.saveFocused + 1
              : previous?.qteRecu
              ? previous?.qteRecu + 1
              : 1,

            rayonObj: { label: res.libRayon, value: res.codeRayon },
            fabObj: { label: res.editFab, value: res.codeEditFab },
          };
        });

        setTimeout(() => {
          focusInput("#product-counter input");
        }, 100);
      } else {
      }
      setInputValue("");
    }
  };

  //Pourra être factorisée avec celle de ReceptionProductCard
  const handleBlur = async (
    key: string,
    value: string,
    product: ReceptionLine,
    receiptLinesBis: { pagination: CommonPagination; items: ReceptionLine[] }
  ) => {
    const orders = await getRelatedOrders(
      selectedReception,
      product.eaN13,
      product.qteRecu
    );

    const resValo = await ReceptionApiClient.valoLine(
      selectedReception,
      {
        ...product,
        [key]: parseFloat(value.replace(",", ".").replace(/[^0-9.]/g, "")), // Temp, there are better way to do that
      },
      key
    );

    const indexP = receiptLinesBis.items.findIndex(
      (p) => p.linePk === product.linePk
    );
    const tempTab = [...receiptLinesBis.items];
    const saveTab = [...receiptLinesBis.items];
    tempTab[indexP] = {
      ...product,
      [key]: parseFloat(value.replace(",", ".").replace(/[^0-9.]/g, "")), // Temp, there are better way to do that
      tauxNet: resValo.tauxNet,
      prixNetHt: resValo.netHT,
    };
    setReceiptLines({ ...receiptLinesBis, items: tempTab });
    const res = await updateLine(
      {
        ...product,
        [key]: parseFloat(value.replace(",", ".").replace(/[^0-9.]/g, "")), // Temp, there are better way to do that
      },
      orders
    );
    if (!res) setReceiptLines({ ...receiptLinesBis, items: saveTab });

    // const lines = await getLines(selectedReception, {
    //   orderBy: "eaN13",
    //   orderDir: "DESC",
    //   pageNumber: 1,
    //   pageSize: 15,
    // });
    // setReceiptLines({
    //   pagination: {
    //     pageNumber: lines.pageNumber,
    //     pageSize: lines.pageSize,
    //     pageCount: lines.pageCount,
    //     totalRows: lines.totalRows,
    //     rowsReturned: lines.rowsReturned + receiptLines.pagination.rowsReturned,
    //   },
    //   items: lines.items,
    // });

    //setTempItem(lines.items[0]);
  };

  //Pourra être factorisée avec celle de ReceptionProductCard
  const handleChange = (key: string, value: string, product: ReceptionLine) => {
    if (product.linePk === selectedItem?.linePk) {
      setSelectedItem({ ...selectedItem, [key]: +value } as ReceptionLine);
    }
    valoLine(product, key, value);

    //setTempItem({ ...selectedItem, [key]: +value } as ReceptionLine);
  };

  const columns: Column<ReceptionLine>[] = [
    {
      title: "Articles",
      render: (p, { responsive }) =>
        responsive ? (
          <div className="text-right" onClick={() => {}}>
            <Base className="cursor-text" data-tooltip={p.eaN13}>
              {p.eaN13}
            </Base>
            <br />
            <Info className="cursor-text">{p.eaN13}</Info>
          </div>
        ) : (
          <div
            className="flex flex-row w-full items-center space-x-4"
            onClick={() => {}}
          >
            {p.imageURL && (
              <div className="w-10 print:hidden">
                <BookRender
                  src={p.imageURL}
                  productType={selectedReception.modeGest || "P"}
                />
              </div>
            )}
            <div className="flex flex-col">
              <Base
                className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal w-full cursor-text"
                data-html={true}
                data-tooltip={getCommonProductTooltip(
                  p,
                  selectedReception.modeGest
                )}
              >
                {p.desig}
              </Base>
              <Info className="cursor-text">{p.eaN13}</Info>
            </div>
          </div>
        ),
    },
    {
      title: `${selectedReception.modeGest === "L" ? "TTC" : "HT PU"} ${
        current?.devisSymb || "€"
      }`,
      render: (p) => (
        <InputFormat
          id="prixBase"
          onClick={(e) => {
            e.stopPropagation();
            setTabFocusedItem(p);
          }}
          disabled={(selectedItem && selectedItem.linePk === p.linePk) || false}
          format="price"
          inputClassName="max-w-24"
          value={p.prixBase}
          onBlur={(e) => {
            //handleBlur("prixBase", e.target.value, p);
          }}
          onChange={(e) => {
            console.log(receiptLines);
            handleChange("prixBase", e.target.value, p);
          }}
        />
      ),
    },
    {
      title: "% remise",
      render: (p) => (
        <InputFormat
          id="tauxNet"
          onClick={(e) => {
            e.stopPropagation();
            setTabFocusedItem(p);
          }}
          disabled={(selectedItem && selectedItem.linePk === p.linePk) || false}
          format="percentage"
          inputClassName="max-w-24"
          value={p.tauxNet}
          onBlur={(e) => {
            //handleBlur("tauxNet", e.target.value, p);
          }}
          onChange={(e) => {
            handleChange("tauxNet", e.target.value, p);
          }}
        />
      ),
    },
    {
      title: `HT PA ${current?.devisSymb || "€"}`,
      render: (p) => (
        <InputFormat
          id="prixNetHt"
          onClick={(e) => {
            e.stopPropagation();
            setTabFocusedItem(p);
          }}
          disabled={(selectedItem && selectedItem.linePk === p.linePk) || false}
          format="price"
          inputClassName="max-w-24"
          value={p.prixNetHt}
          onBlur={(e) => {
            //handleBlur("prixNetHt", e.target.value, p);
          }}
          onChange={(e) => {
            handleChange("prixNetHt", e.target.value, p);
          }}
        />
      ),
    },

    {
      title: "Actions",
      render: (p) => (
        <div className="w-full flex gap-2">
          <ReceptionProductActions
            product={p}
            reception={selectedReception}
            disabled={
              (selectedItem && selectedItem.linePk === p.linePk) || false
            }
          />
        </div>
      ),
    },
  ];

  return (
    <Page loading={loading}>
      <div className="flex flex-col justify-start mx-1 sm:mx-6">
        <div className="grow flex flex-col mt-6 md:flex-row md:justify-around ">
          {!clotureStep && (
            <div className="grow">
              <Title>{t("reception.Reception en cours")}</Title>
            </div>
          )}
        </div>

        {!clotureStep && (
          <div className="grow flex flex-col-reverse gap-4 sm:gap-0 sm:flex-row items-center my-4">
            <form
              className="mx-auto w-full flex h-full w-full bg-red-400 "
              style={{ maxWidth: 540 }}
              autoComplete="off "
              onSubmit={async (e) => {
                e.preventDefault();
                if (inputValue.length >= 8) {
                  await handleSubmit(inputValue);
                }
              }}
            >
              <InputWithSuggestions
                options={[]}
                inputMode={scanOn ? "none" : undefined}
                inputClassName="to-focus"
                autoFocus={true}
                placeholder="Scanner"
                value={inputValue}
                onFocus={(e) => {
                  if (context.scanData) {
                    setInputValue(context.scanData);
                    handleSubmit(context.scanData);
                  }
                }}
                onChange={(e) => setInputValue(e.currentTarget.value)}
              />

              <Button
                className="shrink-0"
                shortcut={["enter"]}
                icon={(p) => <PlusIcon {...p} />}
                onClick={async () => {
                  const elActiv = document.activeElement as HTMLInputElement;
                  if (
                    elActiv &&
                    elActiv.attributes.getNamedItem("format") &&
                    tabFocusedItem
                  ) {
                    handleBlur(
                      elActiv.id,
                      elActiv.value,
                      tabFocusedItem,
                      receiptLines
                    );
                    (elActiv as HTMLInputElement).blur();
                  }
                  await handleSubmit(inputValue);
                }}
              />
            </form>

            <div className="flex gap-8 my-y md:mt-0 items-center">
              <Button
                theme="danger"
                onClick={() => {
                  navigate(ROUTES.Receptions);
                  setTab({ ...tab, header: false });
                }}
                icon={(p) => <ArrowNarrowLeftIcon {...p} />}
              >
                Retour
              </Button>

              <Button
                theme="primary"
                onClick={async () => {
                  setClotureStep(true);
                  setTab({ ...tab, header: true });
                  if (tvaList.length === 0) {
                    const res = await CommonApiClient.getTaxes();
                    setTvaList(
                      res.map((el) => {
                        return { label: el.taux, value: el.code };
                      })
                    );
                    changeSelectedReception({
                      ...selectedReception,
                      codeTaxe: res.find((t) => t.port === true)!.code || "",
                    });
                  }
                }}
                icon={(p) => <CheckCircleIcon {...p} />}
              >
                Clôturer
              </Button>
            </div>
          </div>
        )}

        <div className="flex flex-col mt-4 justify-start h-full w-full md:mt-6 space-y-6 md:space-y-0 gap-1">
          {!clotureStep && selectedItem && (
            <div className="flex flex gap-2">
              <Accordion
                parentClassName="w-full"
                onChange={(newTabValue) => {
                  setTab(newTabValue);
                }}
                value={tab}
                panels={[
                  {
                    value: "selectedItem",
                    label: "Produit",
                    node: (
                      <div
                        className="flex flex-col sm:flex-row justify-start
                     w-full gap-4"
                      >
                        <div className="flex flex-col sm:w-1/4 gap-2">
                          <div className="flex w-full border-2 p-2 gap-2 md:gap-4 justify-start items-center justify-center">
                            <InfoSmall>Étiqueter</InfoSmall>
                            <Button
                              className="grow max-w-6"
                              size="sm"
                              disabled={true}
                              theme="primary"
                              icon={({ className }) => (
                                <TagIcon className={className} />
                              )}
                              onClick={async () => {
                                await ReceptionApiClient.printLabels(
                                  selectedReception,
                                  selectedItem,
                                  2
                                );
                              }}
                            />
                            {selectedItem.qteCdeCli === 0 && (
                              <div style={{ maxWidth: 160, minWidth: 120 }}>
                                {" "}
                                <InputCounter
                                  size="sm"
                                  value={selectedItem.nbrEtiq}
                                  min={0}
                                  onChange={(newValue) => {
                                    setSelectedItem({
                                      ...selectedItem,
                                      nbrEtiq: newValue,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <Frame className="w-full h-full bg-slate-300 flex flex-col gap-4">
                            <div
                              className="select-text"
                              //key={`${selectedItem.prixBase} ${selectedItem.tauxNet} ${selectedItem.prixNetHt}`}
                            >
                              <ReceptionProductCard
                                reception={selectedReception}
                                product={selectedItem}
                                autoFocusQuantity={true}
                                lock={lock}
                              />
                            </div>
                          </Frame>
                          <div className="flex w-full justify-center gap-8">
                            <Button
                              theme="danger"
                              onClick={() => {
                                setTabKey((previous) => {
                                  return { ...previous, refresh: false };
                                });
                                setSelectedItem(null);
                              }}
                            >
                              Annuler
                            </Button>
                            <Button
                              //Will need factorisation later on
                              onClick={async () => {
                                // const res = await updateLine(
                                //   selectedItem,
                                //   orders
                                // );
                                // const lines = await getLines(selectedReception, {
                                //   orderBy: "",
                                //   orderDir: "DESC",
                                //   pageNumber: 1,
                                //   pageSize: 15,
                                // });
                                if (selectedItem.qteCdeCli > 0) {
                                  const orders = await getRelatedOrders(
                                    selectedReception,
                                    selectedItem.eaN13,
                                    selectedItem.qteRecu
                                  );
                                  setAffectationModal({
                                    open: true,
                                    receipt: selectedReception,
                                    item: selectedItem,
                                    orders: orders,
                                  });
                                } else {
                                  await updateLine(
                                    selectedItem,
                                    [
                                      {
                                        codeCanalVente: "",
                                        numCdeCli: "",
                                        dateCde: null,
                                        nomClient: "",
                                        telCli: "",
                                        mailCli: "",
                                        qteCde: 0,
                                        qteRest: 0,
                                        qteProposee: selectedItem.qteRecu,
                                        nbrEtiq: 0,
                                      },
                                    ],
                                    true
                                  );
                                  const updatedLines = await getLines(
                                    selectedReception,
                                    {
                                      orderBy: "eaN13",
                                      orderDir: "DESC",
                                      pageNumber: 0,
                                      pageSize: 15,
                                    },
                                    true
                                  );
                                  setSelectedItem(null);
                                  setReceiptLines({
                                    pagination: {
                                      pageNumber: updatedLines.pageNumber,
                                      pageSize: updatedLines.pageSize,
                                      pageCount: updatedLines.pageCount,
                                      totalRows: updatedLines.totalRows,
                                      rowsReturned: updatedLines.rowsReturned,
                                    },
                                    items: updatedLines.items,
                                  });
                                  setTabKey((previous) => {
                                    return {
                                      count: previous.count + 1,
                                      refresh: true,
                                    };
                                  });
                                  setSelectedItem(null);
                                  setLock(false);
                                }

                                const lines = await getLines(
                                  selectedReception,
                                  {
                                    orderBy: "eaN13",
                                    orderDir: "DESC",
                                    pageNumber: 0,
                                    pageSize: 15,
                                  },
                                  true
                                );
                                setReceiptLines({
                                  pagination: {
                                    pageNumber: lines.pageNumber,
                                    pageSize: lines.pageSize,
                                    pageCount: lines.pageCount,
                                    totalRows: lines.totalRows,
                                    rowsReturned:
                                      lines.rowsReturned +
                                      receiptLines.pagination.rowsReturned,
                                  },
                                  items: lines.items,
                                });
                                setSelectedItem(null);
                              }}
                            >
                              Valider la ligne
                            </Button>
                          </div>
                        </div>
                        <div className="grow mt-2 flex flex-col gap-8">
                          <div className="overflow-visible">
                            <Form
                              onChange={(newValue) => {
                                if (Array.isArray(newValue.rayonObj)) {
                                  if (newValue.rayonObj[0])
                                    setSelectedItem({
                                      ...(newValue as ReceptionLine),
                                      rayonObj: newValue.rayonObj[0],
                                      libRayon: newValue.rayonObj[0].label,
                                      codeRayon: newValue.rayonObj[0].value,
                                    });
                                  else {
                                    setSelectedItem({
                                      ...(newValue as ReceptionLine),
                                      rayonObj: undefined,
                                      libRayon: "",
                                      codeRayon: "",
                                    });
                                  }
                                }
                                if (Array.isArray(newValue.fabObj)) {
                                  if (newValue.fabObj[0])
                                    setSelectedItem({
                                      ...(newValue as ReceptionLine),
                                      fabObj: newValue.fabObj[0],
                                      editFab: newValue.fabObj[0].label,
                                      codeEditFab: newValue.fabObj[0].value,
                                    });
                                  else {
                                    setSelectedItem({
                                      ...(newValue as ReceptionLine),
                                      fabObj: undefined,
                                      editFab: "",
                                      codeEditFab: "",
                                    });
                                  }
                                }
                              }}
                              value={selectedItem}
                              fields={[
                                {
                                  key: "rayonObj",
                                  label: t("Rayon"),
                                  type: "searchselect",
                                  alwaysVisible: true,
                                  options: async (query) => {
                                    const result = await getSuggestions({
                                      Rayon: query,
                                      ModeGestion: selectedReception.modeGest,
                                    });
                                    return result.map((el) => {
                                      return {
                                        label: el.label,
                                        value: el.value,
                                      };
                                    });
                                  },
                                },
                                {
                                  key: "fabObj",
                                  label: t("Fabricant/Editeur"),
                                  type: "searchselect",
                                  alwaysVisible: true,
                                  options: async (query) => {
                                    const result = (await getSuggestions({
                                      Editeur: query,
                                      ModeGestion: selectedReception.modeGest,
                                    })) as { label: string; value: string }[];
                                    return result;
                                  },
                                },
                              ]}
                            />
                          </div>
                          <div className="w-full">
                            <Form
                              onChange={() => {}}
                              value={selectedItem}
                              readonly={true}
                              fieldStyle={{ minWidth: 86, maxWidth: 128 }}
                              fields={[
                                {
                                  key: "prixVenteRef",
                                  label: t("Prix vente"),
                                  type: "formatted",
                                  format: "price",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "margeAchat",
                                  label: t("Marge achat"),
                                  type: "formatted",
                                  format: "percentage",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "margeVente",
                                  label: t("Marge vente"),
                                  type: "formatted",
                                  format: "percentage",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "qteStock",
                                  label: t("Quantite stock"),
                                  type: "number",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "dateDernRecep",
                                  label: t("Date derniere reception"),
                                  type: "date",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "prixPromo",
                                  label: t("Prix promo"),
                                  type: "formatted",
                                  format: "price",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "dateDebut",
                                  label: t("Date debut"),
                                  type: "date",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "dateFin",
                                  label: t("Date fin"),
                                  type: "date",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "codeCoef",
                                  label: t("Coef"),
                                  type: "number",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "codeCondi",
                                  label: t("Conditionnement"),
                                  type: "number",
                                  alwaysVisible: true,
                                },

                                {
                                  key: "codePanie", //N'éxiste pas encore
                                  label: t("Paniere"),
                                  type: "text",
                                  alwaysVisible: true,
                                },

                                {
                                  key: "refer", //N'éxiste pas encore
                                  label: t("Reference"),
                                  type: "text",
                                  alwaysVisible: true,
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />{" "}
            </div>
          )}

          <div className="grow overflow-hidden flex flex-col gap-2">
            <Accordion
              onChange={(newTabValue) => {
                setTab(newTabValue);
              }}
              value={tab}
              panels={[
                {
                  value: "header",
                  label: (
                    <div className="flex flex-row gap-2">
                      <div className="flex flex-col justify-center items-end w-6 h-6">
                        <BookRender
                          src=""
                          productType={selectedReception.modeGest}
                        />
                      </div>
                      <Base>{`${selectedReception.codeFourn} ${
                        selectedReception.numRecep !== ""
                          ? "- Reception " + selectedReception.numRecep
                          : ""
                      } - BL ${selectedReception.numBL} - Bordereau ${
                        selectedReception.bord !== ""
                          ? selectedReception.bord
                          : selectedReception.numBL
                      } `}</Base>
                    </div>
                  ),

                  node: (
                    <Frame className="mb-2">
                      <div className="flex flex-row gap-4 justify-between items-center mb-4">
                        {clotureStep && (
                          <div className="flex gap-6">
                            <Button
                              theme="secondary"
                              onClick={async () => {
                                const res = await getReceiptDocument("recep");
                                if (res)
                                  setPdfModal({
                                    open: true,
                                    pdfProps: {
                                      blobFile: res,
                                      iframeSize: {
                                        width: "80%",
                                        height: "90%",
                                      },
                                      iFrameClassname:
                                        "w-full h-full flex justify-center items-center",
                                    },
                                  });
                              }}
                              icon={(p) => <PrinterIcon {...p} />}
                            >
                              {finishedStep
                                ? "Imprimer bon de réception"
                                : "Imprimer brouillard"}
                            </Button>
                            <Button
                              theme="secondary"
                              onClick={async () =>
                                setMissingArticlesModal({
                                  open: true,
                                  missingArticles: await getMissingArticles(), //Temp data, just for debugging purpose
                                  motifs: await CommonApiClient.getAvailibility(
                                    selectedReception.codeTypeProd
                                  ),
                                })
                              }
                              icon={(p) => <FaArrowRightArrowLeft {...p} />}
                            >
                              Gérer les manquants
                            </Button>
                            {selectedReception.typeRecep === 0 && (
                              <Button
                                theme="secondary"
                                onClick={async () => {
                                  const res = await getReceiptDocument("ecart");
                                  if (res) {
                                    if (res.size === 0) {
                                      toast.success(
                                        "Votre réception ne contient aucun écart"
                                      );
                                    } else {
                                      setPdfModal({
                                        open: true,
                                        pdfProps: {
                                          blobFile: res,
                                          iframeSize: {
                                            width: "80%",
                                            height: "90%",
                                          },
                                          iFrameClassname:
                                            "w-full h-full flex justify-center items-center",
                                        },
                                      });
                                    }
                                  }
                                }}
                                icon={(p) => <PaperClipIcon {...p} />} //Je savais pas quel icône choisir
                              >
                                {t("Imprimer écarts")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                      <Form
                        readonly={true}
                        onChange={() => ""}
                        value={selectedReception}
                        fields={[
                          {
                            key: "codeTypeTaux",
                            label: t("reception.tauxImplicite"),
                            type: "text",
                            alwaysVisible: true,
                          },
                          {
                            key: "codeTypeAchat",
                            label: t("reception.typeAchat"),
                            type: "select",
                            alwaysVisible: true,
                            options: [
                              {
                                label: "Achat ferme",
                                value: "0",
                              },
                              {
                                label: "Office",
                                value: "1",
                              },
                              {
                                label: "Dépôt permanent",
                                value: "2",
                              },
                              {
                                label: "Dépôt occasionnel",
                                value: "3",
                              },
                              {
                                label: "Droit de retour",
                                value: "4",
                              },
                            ],
                          },
                          {
                            key: "recepAffecCdeCli",
                            label: "Affecter les commandes",
                            type: "select",
                            alwaysVisible: true,
                            options: [
                              { label: "Oui", value: "0" },
                              {
                                label: "Non",
                                value: "1",
                              },
                              {
                                label: "Oui/Non",
                                value: "2",
                              },
                              {
                                label: "Non/Oui",
                                value: "3",
                              },
                              {
                                label: "Oui/PS",
                                value: "",
                              },
                            ],
                          },
                          {
                            key: "modeAffecCdeCli",
                            label: t("reception.Mode affectation"),
                            type: "select",
                            alwaysVisible: true,
                            options: [
                              {
                                label: "Défaut (par ancienneté cde cli)",
                                value: "0",
                              },
                              {
                                label: "PRIO (priorité aux cde cli associées)",
                                value: "1",
                              },
                              {
                                label: "EXCL (exclusivement cde cli associées)",
                                value: "2",
                              },
                            ],
                          },
                          {
                            key: "dateMiniAvantRet",
                            label: t("Retour des le"),
                            type: "date",
                            alwaysVisible: true,
                          },
                          {
                            key: "dateMaxiRet",
                            label: t("retourJusquau"),
                            type: "date",
                            alwaysVisible: true,
                          },
                          {
                            key: "jspTag",
                            label: "Étiqueter les commandes",
                            type: "select",
                            options: [
                              { label: "Étiquetage habituel", value: "0" },
                              {
                                label: "Étiqueter toutes les commandes client",
                                value: "1",
                              },
                              {
                                label: "Ne pas étiqueter les commandes client",
                                value: "2",
                              },
                            ],
                            alwaysVisible: true,
                          },
                        ]}
                      />{" "}
                      <div className="flex flex-col mt-4 gap-1">
                        <Info>Commentaire interne</Info>
                        <Input
                          multiline={true}
                          readOnly={finishedStep}
                          value={selectedReception.comm}
                          onChange={(e) =>
                            changeSelectedReception({
                              ...selectedReception,
                              comm: e.target.value,
                            })
                          }
                        />
                      </div>
                    </Frame>
                  ),
                },
              ]}
            />
            {!clotureStep &&
              (selectedReception.modeGest === "L" ||
                selectedReception.listReceiptSupplierOrder) && (
                <Frame>
                  <div className="flex flex-row w-full gap-8 justify-around">
                    {selectedReception.typeRecep !== 1 &&
                      selectedReception.listReceiptSupplierPackage && (
                        <div className="flex flex-row justify-starts w-full items-center">
                          <Info className="text-center ">Afficher colis: </Info>
                          <Select
                            value={selectedReception.numColis}
                            onChange={async (e) => {
                              changeSelectedReception({
                                ...selectedReception,
                                numColis: e.target.value,
                              });
                              const lines = await getLines({
                                ...selectedReception,
                                numColis: e.target.value,
                              });
                              if (lines) {
                                setReceiptLines({
                                  pagination: {
                                    pageNumber: lines.pageNumber,
                                    pageSize: lines.pageSize,
                                    pageCount: lines.pageCount,
                                    totalRows: lines.totalRows,
                                    rowsReturned: lines.rowsReturned,
                                  },
                                  items: lines.items,
                                });
                              }
                            }}
                            size={"md"}
                            placeholder={""}
                          >
                            {[
                              {
                                numColis: "-1",
                                idColis: "",
                                livrNbRef: -1,
                                recepNbRef: -1,
                                livrNb:
                                  selectedReception.listReceiptSupplierPackage.reduce(
                                    (sum, el) =>
                                      (sum = {
                                        ...sum,
                                        livrNb: sum.livrNb + el.livrNb,
                                      })
                                  ).livrNb | 0,
                                recepNb:
                                  selectedReception.listReceiptSupplierPackage.reduce(
                                    (sum, el) =>
                                      (sum = {
                                        ...sum,
                                        recepNb: sum.recepNb + el.recepNb,
                                      })
                                  ).recepNb,
                              },
                            ]
                              .concat(
                                selectedReception.listReceiptSupplierPackage
                              )
                              .map((el, index) => (
                                <option key={index} value={el.numColis}>
                                  {el.numColis !== "-1"
                                    ? `${el.numColis} - ${el.idColis} - ${el.recepNbRef}/${el.livrNbRef} références - ${el.recepNb}/${el.livrNb} articles`
                                    : `Tous - ${el.recepNb}/${el.livrNb} articles`}
                                </option>
                              ))}
                          </Select>
                        </div>
                      )}

                    {selectedReception.modeGest === "L" && (
                      <ButtonConfirm
                        confirmTitle="Mettre à jour les prix des fiches articles"
                        confirmMessage={`Vous êtes sur le point de modifier les fiches articles des produits actuellement présents dans cette réception. Êtes-vous sûr de vouloir continuer ?`}
                        className="shrink-0"
                        size="md"
                        onClick={async (e) => {
                          e.stopPropagation();
                          await updateAllPrices();
                        }}
                        theme="secondary"
                        icon={({ className }) => (
                          <RefreshIcon className={className} />
                        )}
                      >
                        MAJ prix vente
                      </ButtonConfirm>
                    )}
                    {selectedReception.listReceiptSupplierOrder && (
                      <Button
                        className="shrink-0"
                        size="md"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCommandFournModal({
                            open: true,
                            listCde: selectedReception.listReceiptSupplierOrder,
                          });
                        }}
                        theme="secondary"
                        icon={({ className }) => (
                          <ViewListIcon className={className} />
                        )}
                      >
                        {selectedReception.listReceiptSupplierOrder.length > 1
                          ? `Commande fournisseurs associées (${selectedReception.listReceiptSupplierOrder.length})`
                          : `Commande ${selectedReception.listReceiptSupplierOrder[0].numCde} associée`}
                      </Button>
                    )}
                  </div>
                </Frame>
              )}

            {!clotureStep && (
              <div>
                <TableGridSwitch
                  key={`${tabKey}-${selectedItem?.linePk}`}
                  gridMode={gridMode.activated}
                  onChangeMode={(v) => setGridMode({ activated: v })}
                  onClick={async (el) => {
                    if (!window.getSelection()?.toString()) {
                      if (selectedItem && selectedItem.linePk !== el.linePk) {
                        if (selectedItem.qteCdeCli > 0) {
                          const orders = await getRelatedOrders(
                            selectedReception,
                            selectedItem.eaN13,
                            selectedItem.qteRecu
                          );
                          setAffectationModal({
                            open: true,
                            receipt: selectedReception,
                            item: selectedItem,
                            orders: orders,
                            queingItem: el,
                          });
                          return;
                        } else {
                          // await updateLine(selectedItem, [
                          //   {
                          //     codeCanalVente: "",
                          //     numCdeCli: "",
                          //     dateCde: null,
                          //     nomClient: "",
                          //     telCli: "",
                          //     mailCli: "",
                          //     qteCde: 0,
                          //     qteRest: 0,
                          //     qteProposee: selectedItem.qteRecu,
                          //     nbrEtiq: 0,
                          //   },
                          // ]);
                        }
                      }
                      // const updatedLines = await getLines(selectedReception, {
                      //   orderBy: "eaN13",
                      //   orderDir: "DESC",
                      //   pageNumber: 0,
                      //   pageSize: 15,
                      // });
                      // setReceiptLines({
                      //   pagination: {
                      //     pageNumber: updatedLines.pageNumber,
                      //     pageSize: updatedLines.pageSize,
                      //     pageCount: updatedLines.pageCount,
                      //     totalRows: updatedLines.totalRows,
                      //     rowsReturned: updatedLines.rowsReturned,
                      //   },
                      //   items: updatedLines.items,
                      // });

                      setSelectedItem({
                        ...el,
                        nbrEtiq: el.qteCdeCli === 0 ? el.qteRecu : 0,
                        rayonObj: {
                          label: el.libRayon,
                          value: el.codeRayon,
                        },
                        fabObj: {
                          label: el.editFab,
                          value: el.codeEditFab,
                        },
                      });
                      setTabKey((previous) => {
                        return { ...previous, refresh: false };
                      });
                      //setLock(true);
                      setTab({ selectedItem: true });
                      setTimeout(() => {
                        focusInput("#product-counter input");
                      }, 250);
                    }
                  }}
                  renderGrid={(p) => (
                    <ReceptionProductCard
                      product={p}
                      reception={selectedReception}
                      showBorderOnSelected={true}
                      lock={lock}
                      // disabled={selectedItem !== null}
                      disableOnselected={true}
                    />
                  )}
                  tableColumns={
                    selectedReception.modeGest === "L"
                      ? columns.concat({
                          title: "MAJ Prix",
                          render: () => (
                            <div className="w-full flex justify-center">
                              <Button
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  await updateAllPrices();
                                }}
                                theme="secondary"
                                className="rounded-full"
                                data-tooltip="MAJ prix de vente"
                                icon={({ className }) => (
                                  <CurrencyEuroIcon className={className} />
                                )}
                              />
                            </div>
                          ),
                        })
                      : columns
                  }
                  data={receiptLines.items}
                  emptyTabText="Aucune ligne de réception"
                  showPagination={
                    loading || receiptLines.items.length === 0 ? false : "full"
                  }
                  onFetchExportData={async () => {
                    return receiptLines.items;
                  }}
                  total={receiptLines.pagination.totalRows}
                  exportDefaultHeader={["desig", "eaN13"]}
                  initialPagination={{
                    perPage: 15,
                    page: 1,
                    orderBy: 0,
                    order: "DESC",
                  }}
                  onRequestData={async ({ page, perPage, order, orderBy }) => {
                    if (tabKey.refresh) {
                      const lines = await getLines(selectedReception, {
                        orderBy: "eaN13",
                        orderDir: order || "DESC",
                        pageNumber: page,
                        pageSize: 15,
                      });
                      setReceiptLines({
                        pagination: {
                          pageNumber: lines.pageNumber,
                          pageSize: lines.pageSize,
                          pageCount: lines.pageCount,
                          totalRows: lines.totalRows,
                          rowsReturned:
                            lines.rowsReturned +
                            receiptLines.pagination.rowsReturned,
                        },
                        items: lines.items,
                      });
                    }
                    setTabKey((previous) => {
                      return { ...previous, refresh: true };
                    });
                  }}
                  gridClassName={
                    "grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
                  }
                />
              </div>
            )}
            {clotureStep && (
              <div className="flex flex-col gap-4">
                <Frame className="w-full ">
                  <Form
                    readonly={true}
                    fields={receptionInfoModalFields}
                    value={selectedReception}
                    onChange={() => {}}
                  />
                </Frame>
                <div className="w-full flex justify-center mt-0 sm:mt-6">
                  <Form
                    key={`${tvaList.length}`}
                    fields={[
                      {
                        key: "fraisPortHt",
                        label: "Frais de port HT",
                        type: "formatted",
                        format: "price",
                      },
                      {
                        key: "codeTaxe",
                        label: "TVA",
                        type: "select",
                        options: tvaList,
                      },
                      {
                        key: "fraisPortTtc",
                        label: "Frais de port TTC",
                        type: "formatted",
                        format: "price",
                      },
                    ]}
                    value={selectedReception}
                    onChange={(newValue) => {
                      changeSelectedReception(newValue as ReceptionUnit);
                      /* if (
                        newValue.fraisPortTtc !== selectedReception.fraisPortTtc
                      ) {
                        changeSelectedReception({
                          ...newValue,
                          fraisPortHt:
                            parseFloat(newValue.fraisPortTtc) /
                            (1 + parseFloat(newValue.tva) / 100),
                        } as ReceptionUnit);
                      } else {
                        changeSelectedReception({
                          ...newValue,
                          fraisPortTtc:
                            parseFloat(newValue.fraisPortHt) +
                            (parseFloat(newValue.tva) / 100) *
                            parseFloat(newValue.fraisPortHt),
                        } as ReceptionUnit);
                      } */
                    }}
                  />
                </div>

                <div className="flex grow justify-evenly mt-4">
                  {" "}
                  {!finishedStep && (
                    <Button
                      theme="danger"
                      onClick={() => {
                        setClotureStep(false);
                        setTab({ ...tab, header: false });
                      }}
                    >
                      Annuler
                    </Button>
                  )}
                  <Button
                    disabled={loading}
                    onClick={async (e) => {
                      if (!finishedStep) {
                        const res = await closeReceipt();
                        if (res) {
                          setFinishedStep(true);
                          toast.success("Réception clôturée avec succès");
                        }
                      } else navigate(ROUTES.Receptions);
                    }}
                  >
                    {finishedStep
                      ? "Retourner à l'accueil de la réception"
                      : "Valider"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};
